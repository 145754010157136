@import "src/assets/scss/variable.scss";
@import "src/assets/scss/responsive.scss";

.contained-default {
  background: $color-orange-4;
  border-radius: 8px;
  border: none;
  // width: 100%;

  @include styleText(Source Sans Pro, normal, 700, 14px, 20px);
  height: 40px;
  &:hover,
  &:focus {
    background: $color-orange-4;
    color: $color-pure-white;
  }
  &:disabled {
    background: $secondary !important;
    color: $color-black-1 !important;
  }
}

.contained-secondary {
  background: $secondary !important;
  color: $color-black-1 !important;
  border-radius: 8px;
  border: none;
  height: 40px;
  @include styleText(Source Sans Pro, normal, 700, 14px, 20px, $color-black-1);
}

.disable {
  border-radius: 8px;
  border: none;
  background-color: $disable-color !important;
  color: $color-black-1 !important;

  @include styleText(Source Sans Pro, normal, 700, 14px, 20px);
  height: 40px;
}

.btn-dark {
  display: flex;
  height: 40px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  background: $color-grey-14 !important;
  @include styleText(Source Sans Pro, normal, 700, 14px, 20px, $color-2th-white);

  &:hover {
    color: $color-2th-white !important;
    border-color: $color-grey-14 !important;
  }
}
