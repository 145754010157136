@import "src/assets/scss/variable.scss";
@import "src/assets/scss/responsive.scss";

.outlined-default {
  height: 40px;
  border-radius: 8px;
  border: 2px solid var(--input, #d9d9d9);
  background-color: transparent;
  @include styleText(Source Sans Pro, normal, 700, 14px, 20px, $color-grey-14);

  &:hover,
  &:focus {
  }
}

.disable-frac-select {
  opacity: 0.5 !important;
}
.default-h40 {
  @extend .outlined-default;
  height: 40px;
}

.outlined-remove {
  height: 40px;
  background: transparent;
  border-radius: 8px;
  border: 2px solid var(--input, #d9d9d9);
  color: $text-color-red2;
  @include styleText(Source Sans Pro, normal, 700, 14px, 20px, $text-color-red2);

  &:hover,
  &:focus {
    border: 2px solid var(--input, #d9d9d9);
    color: $color-price-red;
  }
}
