@import "src/assets/scss/variable.scss";

.ant-modal-content {
  border-radius: 20px;

  .ant-modal-header {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 40px 40px 0 40px;
    border: none;

    .ant-modal-title {
      font-size: 1.5rem;
      font-family: "Milliard-SemiBold";
      color: $color-neu-black;
    }
  }

  .ant-modal-body {
    padding: 40px;
    max-height: 600px;
    font-size: 1.125rem;
    font-family: "Milliard-Regular";
    color: $color-neu-black;
  }
}

.preview-image-product {
  .ant-modal-content {
    background: transparent !important;
    box-shadow: none;
  }
  .ant-modal-body {
    padding: 0;
    max-width: 960px;
  }
}
