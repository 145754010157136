@import "src/assets/scss/variable.scss";

.upload-item {
  display: inline-block;
  border-radius: 5px;
  border: 1px solid var(--neutral-5, #d9d9d9);
  position: relative;
  overflow: hidden;
  margin: 0 8px 8px 0;
  width: 100%;
  height: 100%;

  img {
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    // object-fit: fill;
    object-fit: cover;
    border-radius: 5px;
  }
  .upload-pdf {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    .icon-pdf {
      font-size: 50px;
      color: rgb(177, 176, 176);
    }
  }
}

.upload-item__frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  transition: opacity 0.3s ease;

  &__remove {
    cursor: pointer;
    align-self: flex-end;
  }

  &__upload {
    cursor: pointer;
    margin-top: 19px;
    display: flex;
    gap: 4px;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color: $color-pure-white;
  }

  .icon-preview {
    cursor: pointer;
    margin-top: 15px;
    font-size: 32px;
    color: $color-pure-white;
  }
}

.upload-item:hover .upload-item__frame {
  opacity: 1;
}
