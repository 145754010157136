.notification {
    border-radius: 10px;
    .ant-notification-notice-message {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
    }
    .ant-notification-notice-description {
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        color: gray;
    }
}