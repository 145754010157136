@import "src/assets/scss/variable.scss";

.login-page {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  position: relative;
  .banner-img {
    background-image: url("../../assets/icons/common/bg-login.png");
    height: 100%;
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__wrapperLogo {
    display: flex;
    max-width: 400px;
    margin: auto;

    .ver-divider {
      width: 2px;
      height: 40px;
      margin: 20px;
      background-color: #e6e8ec;
    }
    p {
      color: $color-neu-black;
      font-weight: 600;
      line-height: 20px;
      text-transform: uppercase;
      margin: auto;
    }
  }

  .login-area {
    margin-top: 40px;
  }

  &__form {
    max-width: 360px;
    margin: auto;
    margin-top: 20%;
    &__area {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    &__info {
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .ant-form-item-label {
    label {
      display: inline-flex;
      flex-direction: row-reverse;
      color: $color-grey-7;
    }
  }
  .ant-card {
    border-radius: 12px;
  }

  .login-btn {
    background: $gradient-blue;
    border: none;
    font-weight: 600;
    border-radius: 8px;
    width: 100%;
  }

  .ant-input-affix-wrapper {
    border-radius: 5px;
    border: 1.5px solid $color-grey-4;
  }

  .custom-fieldset {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    & > p {
      color: $color-grey-6;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      width: 96px;
      margin: 0 10px;
    }
    & > div {
      height: 1px;
      width: 33%;
      background: $color-grey-4;
    }
  }

  .social-btn {
    height: 40px;
    width: 100%;
    border-radius: 8px;
    border: 1.5px solid #d9d9d9;
  }

  .get-code {
    background-color: $color-neu-black;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    color: $color-2th-white;
  }
}

.color-default {
  color: rgba(24, 144, 255, 1);
}

.flex {
  display: flex;
}

.backLogin {
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
  position: absolute;
  top: 0px;
  margin-left: 40px;
  left: 0px;
  div {
    margin-left: 12px;
  }
}

.pointer {
  cursor: pointer;
}
// input, input::placeholder {
//   text-align: center;
// }
.text {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 24px;
  margin: 0px;
}

//Card
.card-container {
  width: 100%;
  height: 100%;
  min-height: 225px;
  min-width: 190px;
  .ant-card-body {
    padding: 25px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.link-text {
  color: rgba(0, 0, 0, 0.85);
  font-size: 33px;
  font-style: normal;
  font-weight: 800;
  line-height: 38px; /* 115.152% */
  letter-spacing: 0.165px;
  margin-bottom: 24px;
  text-align: left;
}
.card-registration {
  border-radius: 2px;
  border-bottom: none;
}

.social-button {
  img {
    cursor: pointer;
  }
}

.countdown {
  text-align: left;
  margin: 12px 0px 20px 0px;
  & > span {
    &:first-child {
      color: #777e90;
      font-weight: 400;
    }

    &:last-child {
      color: #23262f;
      font-weight: 700;
      margin-right: 10px;
    }
  }
}

.password-changed {
  .login-page__form {
    text-align: center;
    h1 {
      font-weight: 600;
      color: $color-grey-8;
      font-size: 36px;
    }
  }
}

.custom-tab {
  .ant-tabs-tab .ant-tabs-tab-btn {
    color: $color-grey-5;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  .ant-tabs-ink-bar {
    background: $color-orange-4;
    height: 3px !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $color-orange-4;
  }
}

.input-label {
  color: $color-grey-7;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 5px;
}

.registration-page {
  .header {
    width: 100%;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.08);
    padding: 15px 100px;
    .login-page__wrapperLogo {
      margin: 0;
    }
  }

  .ant-card {
    border: none;
    border-radius: 15px;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
    &:hover {
      box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12),
        0 5px 12px 4px rgba(0, 0, 0, 0.09);
    }
    &.active {
      box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12),
        0 5px 12px 4px rgba(0, 0, 0, 0.09);
    }
  }
}

.back-btn {
  width: 208px;
}

.primary-btn {
  border-radius: 8px;
  background: $gradient-blue;
  border: none;
  font-size: 14px;
  font-weight: 600;
}

.form-update-password {
  max-width: 500px;
  margin: auto;
  margin-top: 20%;
  .label-tab {
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    margin-bottom: 30px;
    span {
      color: $color-orange-4;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }
  }
  .group-button {
    display: flex;
    gap: 15px;
  }
}

.button-get-code {
  display: flex;
  height: 40px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-top: 26px;
  background: $color-neu-black;
  color: white;
  &:hover {
    background: $color-grey;
    color: white;
    border: 0.5px solid $color-grey;
  }
}
