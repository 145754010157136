@import "src/assets/scss/variable.scss";
@import "src/assets/scss/responsive.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex {
  display: flex;
}

.full-width {
  width: 100%;
}

.center {
  align-items: center;
}

.between {
  justify-content: space-between;
}

.ant-tabs-tabpane {
  outline: none !important;
}

.border-rounded {
  border-radius: 50%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

html *::-webkit-scrollbar {
  border-radius: 0px;
  width: 4px;
  height: 8px;
}
html *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--color_border_common);
}
html *::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: var(--color_background);
}
h1 {
  font-size: 30px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 12px;
}
h6 {
  font-size: 10px;
}
.ant-btn {
  border-radius: 2px;
}
.ant-card {
  border-radius: 2px;
}
.ant-input {
  border-radius: 5px;
}

.chart-card {
  height: 100%;
  .ant-card-head {
    border-bottom: 1px solid $color-grey-4 !important;
  }

  &.ant-card {
    border-radius: 8px;
    background: $color-1th-white;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  }
  .chart-title {
    font-family: "Milliard-Medium";
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: $color-neu-black;
  }
  .chart-amount {
    font-size: 24px;
    color: $color-neu-black;
    font-weight: 600;
    line-height: 32px;
  }
  .chart-sub-title {
    color: $color-grey-6;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}
