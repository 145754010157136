.form-inventory-option {
    .container-sub {
        padding: 20px;
        border: 1px solid rgb(226, 226, 226);
        border-radius: 10px;
        margin-bottom: 10px;
        white-space: 'pre-wrap';
        overflow-wrap: 'break-word';
        gap: 16px;
    }

    .form-container {
        margin-top: 16px;
        gap: 16px;
    }

    .ant-form-item-label {
        padding: 0px;
    }
    .ant-form-item-label label{
        font-size: 12px;
        color: #777E90;
        font-weight: 400;
    }

    .right-button{
        margin-left: auto;
    }
}
  