@import "src/assets/scss/variable.scss";

.upload-multiple {
  .ant-upload {
    border-color: $color-primary-2;
  }
  .upload-label {
    color: $color-primary-2;
    margin-top: 8px;
    line-height: 22px;
  }
}
