@import "src/assets/scss/variable.scss";
@import "src/assets/scss/responsive.scss";

#layout .site-layout-background {
  background: $text-color-white;
}

#layout .logo {
  font-weight: 700;
  font-size: 22px;
  color: #ffffff;
  // width: 232px;
  padding: 0px 16px;
  align-items: center;
  text-align: center;
}

#layout .other {
  width: calc(100% - 232px);
  // background: #0f1f19;
}

#layout .ant-menu-submenu-selected {
  background: rgba(42, 141, 216, 0.2);
  color: white;
  .ant-menu-submenu-title {
    svg {
      path {
        stroke: $color-orange-4;
      }
    }
    i {
      &::before,
      &::after {
        background: $color-orange-4;
      }
    }
  }
}

#layout .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: rgba(42, 141, 216, 0.2);
  border-right: 3px solid $color-orange-4;
  color: white;
  span {
    color: white;
  }
  svg {
    path {
      stroke: $color-orange-4;
    }
  }
}

#layout .ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  border-right: 3px solid rgba(244, 151, 11, 1);
}

#layout .ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #054f31;
}

#layout .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #054f31;
}

#layout .ant-layout.ant-layout-has-sider {
  min-height: calc(100vh - 64px);
}

.ant-layout-sider-trigger {
  display: flex;
  align-items: center;
  background-color: #001529;
  div {
    padding: 10px;
  }
}

.sider-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  .ant-layout-sider-children {
    height: 100vh;
  }
}

.logo-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 18px 16px;
  cursor: pointer;

  .btn-uncollapsed {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: $color-grey-13;
    font-size: 16px;
    &:hover {
      color: $color-pure-white;
    }
  }
  .btn-collapsed {
    display: flex;
    margin-top: 14px;
    size: 12px;
    width: 20px !important;
    height: 20px !important;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 37px;
    background: var(--main, linear-gradient(43deg, #2a8dd8 -48.95%, $color-orange-4 87.76%));
    span {
      width: 12px !important;
      height: 12px !important;
    }
  }
}

.menu-collapsed {
  align-items: center;
  flex-direction: column;

  margin-top: 20px;
  margin-bottom: 11px;
}

#layout .ant-layout {
  background: #fff;
}

#layout .ant-menu-title-content {
  text-transform: capitalize;
}
#layout .breadcrumb {
  font-size: 14px;
  font-weight: 400;
}

#layout .content-wrapper {
  // overflow: hidden;
  margin-bottom: 20px;
  .breadcrumb-container {
    &__header {
      padding: 20px 50px;
      height: 90px;
      display: flex;
      background-color: white;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0px -1px 0px 0px #ebebeb inset;
      .title {
        color: #23262f;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 46px;
      }
    }
    &__router {
      margin-bottom: 30px;
      text-transform: capitalize;
      cursor: pointer;
    }
  }
}

#layout .ant-table-title {
  padding: 0px;
  border: none;
  margin-bottom: 16px;
}
#layout .header {
  display: flex;
  padding: 0px;
  background: $color-nav-black;
  &_user-info {
    .ant-btn-text {
      color: #ffffff !important;
    }
    img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }
}
#layout .content-layout {
  background-color: $color-background-gray;
  // padding: 27px 0px;
  height: 100%;
  min-height: 100vh;
}

#layout .footer {
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__contacts {
    display: flex;
    align-items: center;
    gap: 30px;
    .border {
      height: 55px;
      width: 1px;
      background-color: #000;
      opacity: 0.1;
    }
    .logo {
      img {
        width: 47px;
        height: 55px;
      }
    }
    .contact {
      ul {
        display: flex;
        gap: 40px;
        li {
          a {
            color: #353945;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
          }
        }
      }
      p {
        margin: 0px 0px 0px 20px;
        color: #777e90;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
  &__social {
    display: flex;
    gap: 20px;
  }
}

.search-product {
  max-width: 240px;
  .ant-input-wrapper {
    border-radius: 30px;
  }

  &.ant-input-search .ant-input-group {
    .ant-input-affix-wrapper:not(:last-child) {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      border: 1px solid rgba(0, 0, 0, 0.3);
    }
    .ant-input-group-addon:last-child {
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      border-left: 0;
      background-color: #ffffff;
      padding-right: 10px;
      .ant-input-search-button {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        padding: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
