@import "src/assets/scss/variable.scss";

.form-item {
  .title {
    font-size: 0.875rem;
    // font-family: "Milliard-Regular";
    color: $color-grey-7;

    &__star {
      color: $text-color-red2;
    }
    // & > span {
    //   color: var(--error-color, $color-state-error);
    //   margin-left: 4px;
    // }
  }
}

.ant-form-item-explain {
  margin-top: 6px;
}

.ant-form-item {
  margin-bottom: 20px;
}

// .ant-form-item-explain-error {
//   color: var(--input-error, $color-state-error) !important;
// }
