// @font-face {
//   font-family: "Milliard";
//   font-style: normal;
//   font-weight: 400;
//   src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
//     url(./assets/fonts/ReneBiederMilliardLight.otf) format("woff");
// }

//root font system
@font-face {
  font-family: "Milliard-Thin";
  src: local("Milliard-Thin"), url(./assets/fonts/Milliard-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "Milliard-Light";
  src: local("Milliard-Light"), url(./assets/fonts/Milliard-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "Milliard";
  src: local("Milliard"), url(./assets/fonts/Milliard-Book.ttf) format("truetype");
}
@font-face {
  font-family: "Milliard-Medium";
  src: local("Milliard-Medium"), url(./assets/fonts/Milliard-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Milliard-SemiBold";
  src: local("Milliard-SemiBold"), url(./assets/fonts/Milliard-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Milliard-Bold";
  src: local("Milliard-Bold"), url(./assets/fonts/Milliard-Bold.ttf) format("truetype");
}

* {
  font-family: "Milliard";
}
html {
  // font-size: 10px;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Milliard";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

//scss modal
@import "./assets/scss/modal";

:root {
  --light-gray: #d9d9d9;
}

.body1 {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.body3 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;

  &--grey {
    color: #777E90
  }
}