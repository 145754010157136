.antv-plot-area path {
  fill: rgba(255, 0, 0, 0.2); /* Customize the color and opacity of the area */
}

.custom-tooltip {
  padding: 10px 0px;
  .title {
    color: #23262f;
    font-size: 12px;
    font-weight: 500;
  }
  .content-value {
    display: flex;
    align-items: center;
    gap: 10px;
    &__dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    &__value {
      font-size: 12px;
      color: #23262f;
      text-transform: capitalize;
    }
  }
}
.custom-tooltip-pie-chart {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  gap: 10px;
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  .title {
    margin-bottom: 2px;
    font-size: 12px;
    color: #23262f;
    text-transform: capitalize;
  }
}
.custom-tooltip-column-chart {
  padding-top: 10px;
  min-width: 80px;
  .title {
    font-size: 12px;
    color: #23262f;
    text-transform: capitalize;
    margin-bottom: 10px;
  }
  .content-value {
    display: flex;
    align-items: center;
    margin: 10px 0px;
    gap: 10px;
    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    .value {
      font-size: 12px;
      color: #23262f;
      text-transform: capitalize;
      margin-bottom: 2px;
    }
  }
}
