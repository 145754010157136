@import "src/assets/scss/variable.scss";
@import "src/assets/scss/responsive.scss";

.home-wrapper {
  .home-header {
    background-color: $color-pure-white;
  }
  .ant-btn {
    border-radius: 10px;
    padding: 10px 20px;
    font-weight: 500;
    line-height: 18px;
    height: 40px;
  }
  .product-wrapper {
    // background-color: $color-background-gray;
    padding: 27px 0px;
    height: 100%;
    min-height: 100vh;
  }
}
.ant-btn {
  border-radius: 12px;
}
.form-changePassword {
  margin-top: 60px;
}
.group-header {
  .align-right {
    text-align: right;
  }
}
.title-chart {
  color: $color-grey-12;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
}
.wrapper {
  height: 100%;
  margin: 40px;
  .form-change-password {
    max-width: 500px;
    margin: auto;
    margin-top: 130px;
    .label-tab {
      display: flex;
      align-items: center;
      gap: 15px;
      cursor: pointer;
      margin-bottom: 30px;
      span {
        color: $color-orange-4;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
      }
    }
    .group-button {
      display: flex;
      gap: 15px;
    }
    .ant-form-item-required {
      display: inline-flex;
      flex-direction: row-reverse;
      color: #353945;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
}
