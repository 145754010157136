.modal-confirm {
    .ant-modal-content {
        border-radius: 20px;
        padding: 40px;
        .ant-modal-close{
          position: absolute;
          top: 11%;
          right: 6%;
          .ant-modal-close-x { 
            span {
              border: 0.5px solid #E6E8EC;
              width: 40px;
              height: 40px;
              text-align: center;
              border-radius: 50%;
              align-items: center;
              justify-content: center;
              svg {
                color: #23262F;
              }
            }
          }
        }
        .ant-modal-header{
          padding: 0px 0px 4px 0px;
          border-bottom: none;
          .ant-modal-title{
            color: #23262F;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
          }
        }
        .ant-modal-body {
          padding: 25px 0px;
          color: #23262F;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
        }
        .ant-modal-footer {
          padding: 0px;
          border-top: none;
        }
      }
}